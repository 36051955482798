




























































import Vue from 'vue';
import Tablet from '@/components/Tablet.vue'

export default Vue.extend({
  name: 'GameMap',
  components: {
    Tablet
  },
  props: {
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: false,
      markerInfo: null,
    }
  },
  computed: {
    team () {
      return this.$store.getters.team
    },
    completedAquaTheater () {
      return (this.team.ready && this.team.completedAquaTheater)
    },
    completedBoardwalk () {
      return (this.team.ready && this.team.completedBoardwalk)
    },
    completedBridge () {
      return (this.team.ready && this.team.completedBridge)
    },
    completedCentralPark () {
      return (this.team.ready && this.team.completedCentralPark)
    },
    completedPromenade () {
      return (this.team.ready && this.team.completedPromenade)
    },
    completedTheater () {
      return (this.team.ready && this.team.completedTheater)
    },
    boardwalkLocked () {
      return (!(this.team.ready
        && this.team.completedAquaTheater
      ))
    },
    centralParkLocked () {
      return (!(this.team.ready
        && this.team.completedAquaTheater
        && this.team.completedBoardwalk
      ))
    },
    promenadeLocked () {
      return (!(this.team.ready
        && this.team.completedAquaTheater
        && this.team.completedBoardwalk
        && this.team.completedCentralPark
      ))
    },
    theaterLocked () {
      return (!(this.team.ready
        && this.team.completedAquaTheater
        && this.team.completedBoardwalk
        && this.team.completedCentralPark
        && this.team.completedPromenade
      ))
    },
    bridgeLocked () {
      return (!(this.team.ready
        && this.team.completedAquaTheater
        && this.team.completedBoardwalk
        && this.team.completedCentralPark
        && this.team.completedPromenade
        && this.team.completedTheater
      ))
    }
  },
  methods: {
    show () {
      console.log('GameMap.show')
      this.$refs.tablet.show()
    },
    hide () {
      this.$refs.tablet.hide()
    },
    showMarkerInfo (location: string|null) {
      if (!location) {
        this.markerInfo = null
        return
      }
      this.markerInfo = {
        location: location
      }
    },
    goto (loc: string, heading: string) {
      if (loc === this.$router.currentRoute.path) {
        this.hide()
      } else if (loc === '/bridge' && this.bridgeLocked) {
        // do nothing
      } else {
        this.$emit('navclick', loc, heading, 1500)
      }
    }
  },
  mounted () {
    this.active = this.startActive
  }
});
