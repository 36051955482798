






























import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'

export default Vue.extend({
  name: 'GameHud',
  props: {
    elements: {
      type: Array,
      default: ()=>{
        return [
          'teamName',
          'progress',
          'timer',
          'leaderboard',
          'current-location',
          'map-button',
          'hints'
        ]
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'teamName',
      'timerText',
      'stagesComplete',
      'hintsRemaining',
      'teamRankings'
    ]),
    elementIsIncluded () {
      const isIncluded = {
        'teamName': false,
        'progress': false,
        'timer': false,
        'leaderboard': false,
        'current-location': false,
        'map-button': false,
        'hints': false,
      }
      for (let element in isIncluded) {
        if (this.elements.indexOf(element) !== -1) isIncluded[element] = true;
      }
      return isIncluded
    },
    currentLocation () {
      return this.$route.name
    },
    stage1complete () {
      return (this.stagesComplete >= 1) ? 'bi-unlock-fill' : 'bi-lock'
    },
    stage2complete () {
      return (this.stagesComplete >= 2) ? 'bi-unlock-fill' : 'bi-lock'
    },
    stage3complete () {
      return (this.stagesComplete >= 3) ? 'bi-unlock-fill' : 'bi-lock'
    },
    stage4complete () {
      return (this.stagesComplete >= 4) ? 'bi-unlock-fill' : 'bi-lock'
    },
    stage5complete () {
      return (this.stagesComplete >= 5) ? 'bi-unlock-fill' : 'bi-lock'
    },
    hintIcon1 () {
      return (this.hintsRemaining >= 1) ? 'bi-heart-fill' : 'bi-heart'
    },
    hintIcon2 () {
      return (this.hintsRemaining >= 2) ? 'bi-heart-fill' : 'bi-heart'
    },
    hintIcon3 () {
      return (this.hintsRemaining >= 3) ? 'bi-heart-fill' : 'bi-heart'
    },
    hintIcon4 () {
      return (this.hintsRemaining >= 4) ? 'bi-heart-fill' : 'bi-heart'
    },
    topThree () {
      if (this.teamRankings.length <= 3) return this.teamRankings
      return this.teamRankings.slice(0,3)
    }
  },
  methods: {
    ...mapActions([
    ]),
    emitClick (action: string) {
      this.$emit('click', action)
    },
    goto (loc: string, heading: string) {
      if (loc === this.$router.currentRoute.path) {
        this.hide()
      } else {
        this.$emit('navclick', loc, heading)
      }
    },
  },
  mounted () {
    console.log('GameHud.mounted')
    console.log('  teamRankings', this.teamRankings)
  },
  watch: {
    teamRankings () {
      console.log('  teamRankings', this.teamRankings)
    }
  }
});
